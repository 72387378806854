import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyPricing = lazy(
  () => import("./pricing-L870j0ei.js").then((module) => ({
    default: module.Pricing
  }))
);
function Pricing({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyPricing, { ...props });
}
const usePricingContent = () => {
  return useMemo(
    () => ({
      pricing: {
        component: Pricing
      }
    }),
    []
  );
};
export {
  LazyPricing as Pricing,
  usePricingContent
};
